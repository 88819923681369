var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
!function (e, t) {
  exports = t();
}(exports, function () {
  return function () {
    "use strict";

    var e = {
        27: function (e, t) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.DEFAULT_DOMAIN = t.POPUP_SIZE = t.SLIDER_WIDTH = t.SLIDER_POSITION = t.SIDETAB_ATTRIBUTE = t.WIDGET_ATTRIBUTE = t.SLIDER_ATTRIBUTE = t.POPUP_ATTRIBUTE = t.POPOVER_ATTRIBUTE = void 0, t.POPOVER_ATTRIBUTE = "data-tf-popover", t.POPUP_ATTRIBUTE = "data-tf-popup", t.SLIDER_ATTRIBUTE = "data-tf-slider", t.WIDGET_ATTRIBUTE = "data-tf-widget", t.SIDETAB_ATTRIBUTE = "data-tf-sidetab", t.SLIDER_POSITION = "right", t.SLIDER_WIDTH = 800, t.POPUP_SIZE = 100, t.DEFAULT_DOMAIN = "form.typeform.com";
        },
        528: function (e, t, n) {
          var o = (this || _global) && (this || _global).__assign || function () {
              return o = Object.assign || function (e) {
                for (var t, n = 1, o = arguments.length; n < o; n++) for (var r in t = arguments[n]) Object.prototype.hasOwnProperty.call(t, r) && (e[r] = t[r]);
                return e;
              }, o.apply(this || _global, arguments);
            },
            r = (this || _global) && (this || _global).__rest || function (e, t) {
              var n = {};
              for (var o in e) Object.prototype.hasOwnProperty.call(e, o) && t.indexOf(o) < 0 && (n[o] = e[o]);
              if (null != e && "function" == typeof Object.getOwnPropertySymbols) {
                var r = 0;
                for (o = Object.getOwnPropertySymbols(e); r < o.length; r++) t.indexOf(o[r]) < 0 && Object.prototype.propertyIsEnumerable.call(e, o[r]) && (n[o[r]] = e[o[r]]);
              }
              return n;
            };
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.createPopover = void 0;
          var i = n(747),
            a = n(320),
            u = function (e, t) {
              var n = e.parentNode;
              n && (n.removeChild(e), n.appendChild(t));
            },
            c = function (e, t) {
              void 0 === e && (e = "div"), void 0 === t && (t = "tf-v1-popover-button-icon");
              var n = document.createElement(e);
              return n.className = t + " tf-v1-close-icon", n.innerHTML = "&times;", n.dataset.testid = t, n;
            },
            s = {
              buttonColor: "#3a7685"
            };
          t.createPopover = function (e, t) {
            void 0 === t && (t = {});
            var n,
              d,
              l,
              f = o(o({}, s), t),
              p = f.domain,
              v = r(f, ["domain"]),
              m = i.createIframe("popover", {
                formId: e,
                domain: p,
                options: v
              }),
              h = m.iframe,
              y = m.embedId,
              b = m.refresh,
              g = m.focus,
              w = function (e, t) {
                var n = document.createElement("div");
                return n.className = "tf-v1-popover", n.dataset.testid = "tf-v1-popover", i.setElementSize(n, {
                  width: e,
                  height: t
                });
              }(v.width, v.height),
              O = function () {
                var e = document.createElement("div");
                return e.className = "tf-v1-popover-wrapper", e.dataset.testid = "tf-v1-popover-wrapper", e;
              }(),
              _ = function (e, t) {
                var n = i.getTextColor(t),
                  o = document.createElement("div");
                o.className = "tf-v1-popover-button-icon";
                var r = "<svg class=\"default\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n    <path d=\"M21 0H0V9L10.5743 24V16.5H21C22.6567 16.5 24 15.1567 24 13.5V3C24 1.34325 22.6567 0 21 0ZM7.5\n    9.75C6.672 9.75 6 9.07875 6 8.25C6 7.42125 6.672 6.75 7.5 6.75C8.328 6.75 9 7.42125 9 8.25C9 9.07875 8.328 9.75\n    7.5 9.75ZM12.75 9.75C11.922 9.75 11.25 9.07875 11.25 8.25C11.25 7.42125 11.922 6.75 12.75 6.75C13.578 6.75 14.25\n    7.42125 14.25 8.25C14.25 9.07875 13.578 9.75 12.75 9.75ZM18 9.75C17.172 9.75 16.5 9.07875 16.5 8.25C16.5 7.42125\n    17.172 6.75 18 6.75C18.828 6.75 19.5 7.42125 19.5 8.25C19.5 9.07875 18.828 9.75 18 9.75Z\" fill=\"" + n + "\"></path>\n  </svg>",
                  a = null == e ? void 0 : e.startsWith("http");
                return o.innerHTML = a ? "<img alt='popover trigger icon button' src='" + e + "'/>" : null != e ? e : r, o.dataset.testid = "default-icon", o;
              }(v.customIcon, v.buttonColor || s.buttonColor),
              E = function () {
                var e = document.createElement("div");
                e.className = "tf-v1-spinner";
                var t = document.createElement("div");
                return t.className = "tf-v1-popover-button-icon", t.dataset.testid = "spinner-icon", t.append(e), t;
              }(),
              P = c(),
              j = c("a", "tf-v1-popover-close"),
              I = function (e) {
                var t = i.getTextColor(e),
                  n = document.createElement("button");
                return n.className = "tf-v1-popover-button", n.dataset.testid = "tf-v1-popover-button", n.style.backgroundColor = e, n.style.color = t, n;
              }(v.buttonColor || s.buttonColor);
            (v.container || document.body).append(w), O.append(h), w.append(I), w.append(j), I.append(_);
            var C = function () {
              d && d.parentNode && (d.classList.add("closing"), setTimeout(function () {
                i.unmountElement(d);
              }, 250));
            };
            v.tooltip && v.tooltip.length > 0 && (d = function (e, t) {
              var n = document.createElement("span");
              n.className = "tf-v1-popover-tooltip-close", n.dataset.testid = "tf-v1-popover-tooltip-close", n.innerHTML = "&times;", n.onclick = t;
              var o = document.createElement("div");
              o.className = "tf-v1-popover-tooltip-text", o.innerHTML = e;
              var r = document.createElement("div");
              return r.className = "tf-v1-popover-tooltip", r.dataset.testid = "tf-v1-popover-tooltip", r.appendChild(o), r.appendChild(n), r;
            }(v.tooltip, C), w.append(d)), v.notificationDays && (v.enableSandbox || a.canBuildNotificationDot(e)) && (l = a.buildNotificationDot(), I.append(l)), h.onload = function () {
              w.classList.add("open"), O.style.opacity = "1", j.style.opacity = "1", u(E, P), i.addCustomKeyboardListener(M);
            };
            var T = i.makeAutoResize(w),
              S = function () {
                i.isOpen(O) || (C(), l && (l.classList.add("closing"), v.notificationDays && !v.enableSandbox && a.saveNotificationDotHideUntilTime(e, v.notificationDays), setTimeout(function () {
                  i.unmountElement(l);
                }, 250)), T(), window.addEventListener("resize", T), setTimeout(function () {
                  i.isInPage(O) ? (O.style.opacity = "0", j.style.opacity = "0", O.style.display = "flex", setTimeout(function () {
                    w.classList.add("open"), O.style.opacity = "1", j.style.opacity = "1";
                  }), u(_, P)) : (w.append(O), u(_, E), O.style.opacity = "0", j.style.opacity = "0");
                }));
              },
              M = function () {
                var e;
                i.isOpen(w) && (null === (e = t.onClose) || void 0 === e || e.call(t), setTimeout(function () {
                  v.keepSession ? O.style.display = "none" : i.unmountElement(O), w.classList.remove("open"), u(P, _);
                }, 250));
              };
            i.setAutoClose(y, v.autoClose, M);
            var L = function () {
              i.isOpen(O) ? M() : S();
            };
            return I.onclick = L, j.onclick = M, v.open && !i.isOpen(O) && (n = i.handleCustomOpen(S, v.open, v.openValue)), {
              open: S,
              close: M,
              toggle: L,
              refresh: b,
              focus: g,
              unmount: function () {
                i.unmountElement(w), window.removeEventListener("resize", T), v.open && (null == n ? void 0 : n.remove) && n.remove();
              }
            };
          };
        },
        797: function (e, t, n) {
          var o = (this || _global) && (this || _global).__createBinding || (Object.create ? function (e, t, n, o) {
              void 0 === o && (o = n), Object.defineProperty(e, o, {
                enumerable: !0,
                get: function () {
                  return t[n];
                }
              });
            } : function (e, t, n, o) {
              void 0 === o && (o = n), e[o] = t[n];
            }),
            r = (this || _global) && (this || _global).__exportStar || function (e, t) {
              for (var n in e) "default" === n || Object.prototype.hasOwnProperty.call(t, n) || o(t, e, n);
            };
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), r(n(528), t), r(n(100), t);
        },
        320: function (e, t) {
          var n = (this || _global) && (this || _global).__assign || function () {
            return n = Object.assign || function (e) {
              for (var t, n = 1, o = arguments.length; n < o; n++) for (var r in t = arguments[n]) Object.prototype.hasOwnProperty.call(t, r) && (e[r] = t[r]);
              return e;
            }, n.apply(this || _global, arguments);
          };
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.buildNotificationDot = t.canBuildNotificationDot = t.saveNotificationDotHideUntilTime = void 0;
          var o = "tfNotificationData",
            r = function () {
              var e = localStorage.getItem(o);
              return e ? JSON.parse(e) : {};
            },
            i = function (e) {
              e && localStorage.setItem(o, JSON.stringify(e));
            };
          t.saveNotificationDotHideUntilTime = function (e, t) {
            var o,
              a = new Date();
            a.setDate(a.getDate() + t), i(n(n({}, r()), ((o = {})[e] = {
              hideUntilTime: a.getTime()
            }, o)));
          }, t.canBuildNotificationDot = function (e) {
            var t = function (e) {
              var t;
              return (null === (t = r()[e]) || void 0 === t ? void 0 : t.hideUntilTime) || 0;
            }(e);
            return new Date().getTime() > t && (t && function (e) {
              var t = r();
              delete t[e], i(t);
            }(e), !0);
          }, t.buildNotificationDot = function () {
            var e = document.createElement("span");
            return e.className = "tf-v1-popover-unread-dot", e.dataset.testid = "tf-v1-popover-unread-dot", e;
          };
        },
        100: function (e, t) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          });
        },
        630: function (e, t, n) {
          var o = (this || _global) && (this || _global).__rest || function (e, t) {
            var n = {};
            for (var o in e) Object.prototype.hasOwnProperty.call(e, o) && t.indexOf(o) < 0 && (n[o] = e[o]);
            if (null != e && "function" == typeof Object.getOwnPropertySymbols) {
              var r = 0;
              for (o = Object.getOwnPropertySymbols(e); r < o.length; r++) t.indexOf(o[r]) < 0 && Object.prototype.propertyIsEnumerable.call(e, o[r]) && (n[o[r]] = e[o[r]]);
            }
            return n;
          };
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.createPopup = void 0;
          var r = n(747),
            i = n(27),
            a = n(747);
          t.createPopup = function (e, t) {
            if (void 0 === t && (t = {}), !r.hasDom()) return {
              open: function () {},
              close: function () {},
              toggle: function () {},
              refresh: function () {},
              focus: function () {},
              unmount: function () {}
            };
            var n,
              u = t.width,
              c = t.height,
              s = t.size,
              d = void 0 === s ? i.POPUP_SIZE : s,
              l = t.onClose,
              f = t.domain,
              p = o(t, ["width", "height", "size", "onClose", "domain"]),
              v = r.createIframe("popup", {
                formId: e,
                domain: f,
                options: p
              }),
              m = v.iframe,
              h = v.embedId,
              y = v.refresh,
              b = v.focus,
              g = document.body.style.overflow,
              w = function () {
                var e = document.createElement("div");
                return e.className = "tf-v1-popup", e.dataset.testid = "tf-v1-popup", e.style.opacity = "0", e;
              }(),
              O = function () {
                var e = document.createElement("div");
                return e.className = "tf-v1-spinner", e;
              }(),
              _ = function (e, t, n) {
                var o = document.createElement("div");
                return o.className = "tf-v1-iframe-wrapper", o.style.opacity = "0", r.isDefined(e) && r.isDefined(t) ? r.setElementSize(o, {
                  width: e,
                  height: t
                }) : (o.style.width = "calc(" + n + "% - 80px)", o.style.height = "calc(" + n + "% - 80px)", o);
              }(u, c, d);
            _.append(m), w.append(O), w.append(_);
            var E = p.container || document.body;
            m.onload = function () {
              _.style.opacity = "1", setTimeout(function () {
                O.style.display = "none";
              }, 250), r.addCustomKeyboardListener(I);
            };
            var P = a.makeAutoResize(w),
              j = function () {
                a.isOpen(w) || (a.isInPage(w) ? w.style.display = "flex" : (O.style.display = "block", E.append(w)), document.body.style.overflow = "hidden", P(), window.addEventListener("resize", P), setTimeout(function () {
                  w.style.opacity = "1";
                }));
              },
              I = function () {
                a.isOpen(w) && (null == l || l(), w.style.opacity = "0", document.body.style.overflow = g, setTimeout(function () {
                  p.keepSession ? w.style.display = "none" : C();
                }, 250));
              };
            _.append(function (e) {
              var t = document.createElement("a");
              return t.className = "tf-v1-close tf-v1-close-icon", t.innerHTML = "&times;", t.onclick = e, t;
            }(I)), r.setAutoClose(h, p.autoClose, I), p.open && !a.isOpen(w) && (n = r.handleCustomOpen(j, p.open, p.openValue));
            var C = function () {
              r.unmountElement(w), window.removeEventListener("resize", P), p.open && (null == n ? void 0 : n.remove) && n.remove();
            };
            return {
              open: j,
              close: I,
              toggle: function () {
                a.isOpen(w) ? I() : j();
              },
              refresh: y,
              focus: b,
              unmount: C
            };
          };
        },
        970: function (e, t, n) {
          var o = (this || _global) && (this || _global).__createBinding || (Object.create ? function (e, t, n, o) {
              void 0 === o && (o = n), Object.defineProperty(e, o, {
                enumerable: !0,
                get: function () {
                  return t[n];
                }
              });
            } : function (e, t, n, o) {
              void 0 === o && (o = n), e[o] = t[n];
            }),
            r = (this || _global) && (this || _global).__exportStar || function (e, t) {
              for (var n in e) "default" === n || Object.prototype.hasOwnProperty.call(t, n) || o(t, e, n);
            };
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), r(n(630), t), r(n(394), t);
        },
        394: function (e, t) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          });
        },
        382: function (e, t, n) {
          var o = (this || _global) && (this || _global).__assign || function () {
              return o = Object.assign || function (e) {
                for (var t, n = 1, o = arguments.length; n < o; n++) for (var r in t = arguments[n]) Object.prototype.hasOwnProperty.call(t, r) && (e[r] = t[r]);
                return e;
              }, o.apply(this || _global, arguments);
            },
            r = (this || _global) && (this || _global).__rest || function (e, t) {
              var n = {};
              for (var o in e) Object.prototype.hasOwnProperty.call(e, o) && t.indexOf(o) < 0 && (n[o] = e[o]);
              if (null != e && "function" == typeof Object.getOwnPropertySymbols) {
                var r = 0;
                for (o = Object.getOwnPropertySymbols(e); r < o.length; r++) t.indexOf(o[r]) < 0 && Object.prototype.propertyIsEnumerable.call(e, o[r]) && (n[o[r]] = e[o[r]]);
              }
              return n;
            };
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.createSidetab = void 0;
          var i = n(747),
            a = {
              buttonColor: "#3a7685",
              buttonText: "Launch me"
            },
            u = function (e, t) {
              void 0 === e && (e = "div"), void 0 === t && (t = "tf-v1-sidetab-button-icon");
              var n = document.createElement(e);
              return n.className = t + " tf-v1-close-icon", n.innerHTML = "&times;", n.dataset.testid = t, n;
            },
            c = function (e, t) {
              var n = e.parentNode;
              n && (n.removeChild(e), n.appendChild(t));
            };
          t.createSidetab = function (e, t) {
            void 0 === t && (t = {});
            var n,
              s = o(o({}, a), t),
              d = s.domain,
              l = r(s, ["domain"]),
              f = i.createIframe("side-tab", {
                formId: e,
                domain: d,
                options: l
              }),
              p = f.iframe,
              v = f.embedId,
              m = f.refresh,
              h = f.focus,
              y = function (e, t) {
                var n = document.createElement("div");
                return n.className = "tf-v1-sidetab", n.dataset.testid = "tf-v1-sidetab", i.setElementSize(n, {
                  width: e,
                  height: t
                });
              }(l.width, l.height),
              b = function () {
                var e = document.createElement("div");
                return e.className = "tf-v1-sidetab-wrapper", e.dataset.testid = "tf-v1-sidetab-wrapper", e;
              }(),
              g = function () {
                var e = document.createElement("div");
                e.className = "tf-v1-spinner";
                var t = document.createElement("div");
                return t.className = "tf-v1-sidetab-button-icon", t.dataset.testid = "spinner-icon", t.append(e), t;
              }(),
              w = function (e) {
                var t = i.getTextColor(e),
                  n = document.createElement("button");
                return n.className = "tf-v1-sidetab-button", n.style.backgroundColor = e, n.style.color = t, n;
              }(l.buttonColor || a.buttonColor),
              O = function (e) {
                var t = document.createElement("span");
                return t.className = "tf-v1-sidetab-button-text", t.innerHTML = e, t;
              }(l.buttonText || a.buttonText),
              _ = function (e, t) {
                var n = i.getTextColor(t),
                  o = document.createElement("div");
                o.className = "tf-v1-sidetab-button-icon";
                var r = "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n    <path d=\"M21 0H0V9L10.5743 24V16.5H21C22.6567 16.5 24 15.1567 24 13.5V3C24 1.34325 22.6567 0 21 0ZM7.5 9.75C6.672 9.75 6 9.07875 6 8.25C6 7.42125 6.672 6.75 7.5 6.75C8.328 6.75 9 7.42125 9 8.25C9 9.07875 8.328 9.75 7.5 9.75ZM12.75 9.75C11.922 9.75 11.25 9.07875 11.25 8.25C11.25 7.42125 11.922 6.75 12.75 6.75C13.578 6.75 14.25 7.42125 14.25 8.25C14.25 9.07875 13.578 9.75 12.75 9.75ZM18 9.75C17.172 9.75 16.5 9.07875 16.5 8.25C16.5 7.42125 17.172 6.75 18 6.75C18.828 6.75 19.5 7.42125 19.5 8.25C19.5 9.07875 18.828 9.75 18 9.75Z\" fill=\"" + n + "\"></path>\n  </svg>",
                  a = null == e ? void 0 : e.startsWith("http");
                return o.innerHTML = a ? "<img alt='popover trigger icon button' src='" + e + "'/>" : null != e ? e : r, o.dataset.testid = "default-icon", o;
              }(l.customIcon, l.buttonColor || a.buttonColor),
              E = u(),
              P = u("a", "tf-v1-sidetab-close");
            (l.container || document.body).append(y), b.append(p), y.append(w), y.append(P), w.append(_), w.append(O), setTimeout(function () {
              y.classList.add("ready");
            }, 250), p.onload = function () {
              y.classList.add("open"), c(g, E), i.addCustomKeyboardListener(C);
            };
            var j = i.makeAutoResize(y),
              I = function () {
                i.isOpen(b) || (j(), window.addEventListener("resize", j), i.isInPage(b) ? (b.style.display = "flex", y.classList.add("open"), c(_, E)) : (y.append(b), c(_, g)));
              },
              C = function () {
                var e;
                i.isOpen(b) && (null === (e = l.onClose) || void 0 === e || e.call(l), y.classList.remove("open"), setTimeout(function () {
                  l.keepSession ? b.style.display = "none" : i.unmountElement(b), c(E, _);
                }, 250));
              };
            i.setAutoClose(v, l.autoClose, C);
            var T = function () {
              i.isOpen(b) ? C() : I();
            };
            return w.onclick = T, P.onclick = C, l.open && !i.isOpen(b) && (n = i.handleCustomOpen(I, l.open, l.openValue)), {
              open: I,
              close: C,
              toggle: T,
              refresh: m,
              focus: h,
              unmount: function () {
                i.unmountElement(y), window.removeEventListener("resize", j), l.open && (null == n ? void 0 : n.remove) && n.remove();
              }
            };
          };
        },
        434: function (e, t, n) {
          var o = (this || _global) && (this || _global).__createBinding || (Object.create ? function (e, t, n, o) {
              void 0 === o && (o = n), Object.defineProperty(e, o, {
                enumerable: !0,
                get: function () {
                  return t[n];
                }
              });
            } : function (e, t, n, o) {
              void 0 === o && (o = n), e[o] = t[n];
            }),
            r = (this || _global) && (this || _global).__exportStar || function (e, t) {
              for (var n in e) "default" === n || Object.prototype.hasOwnProperty.call(t, n) || o(t, e, n);
            };
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), r(n(382), t), r(n(668), t);
        },
        668: function (e, t) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          });
        },
        603: function (e, t, n) {
          var o = (this || _global) && (this || _global).__rest || function (e, t) {
            var n = {};
            for (var o in e) Object.prototype.hasOwnProperty.call(e, o) && t.indexOf(o) < 0 && (n[o] = e[o]);
            if (null != e && "function" == typeof Object.getOwnPropertySymbols) {
              var r = 0;
              for (o = Object.getOwnPropertySymbols(e); r < o.length; r++) t.indexOf(o[r]) < 0 && Object.prototype.propertyIsEnumerable.call(e, o[r]) && (n[o[r]] = e[o[r]]);
            }
            return n;
          };
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.createSlider = void 0;
          var r = n(747),
            i = n(27);
          t.createSlider = function (e, t) {
            if (void 0 === t && (t = {}), !r.hasDom()) return {
              open: function () {},
              close: function () {},
              toggle: function () {},
              refresh: function () {},
              focus: function () {},
              unmount: function () {}
            };
            var n,
              a = t.position,
              u = void 0 === a ? i.SLIDER_POSITION : a,
              c = t.width,
              s = void 0 === c ? i.SLIDER_WIDTH : c,
              d = t.onClose,
              l = t.domain,
              f = o(t, ["position", "width", "onClose", "domain"]),
              p = r.createIframe("slider", {
                formId: e,
                domain: l,
                options: f
              }),
              v = p.iframe,
              m = p.embedId,
              h = p.refresh,
              y = p.focus,
              b = document.body.style.overflow,
              g = function (e) {
                var t = document.createElement("div");
                return t.className = "tf-v1-slider " + e, t.dataset.testid = "tf-v1-slider", t.style.opacity = "0", t;
              }(u),
              w = function () {
                var e = document.createElement("div");
                return e.className = "tf-v1-spinner", e;
              }(),
              O = function (e, t) {
                var n = document.createElement("div");
                return n.className = "tf-v1-iframe-wrapper", n.style[e] = "-100%", r.setElementSize(n, {
                  width: t
                });
              }(u, s);
            O.append(v), g.append(w), g.append(O);
            var _ = f.container || document.body;
            v.onload = function () {
              O.style[u] = "0", setTimeout(function () {
                w.style.display = "none";
              }, 500), r.addCustomKeyboardListener(j);
            };
            var E = r.makeAutoResize(g),
              P = function () {
                r.isOpen(g) || (E(), window.addEventListener("resize", E), r.isInPage(g) ? (g.style.display = "flex", setTimeout(function () {
                  O.style[u] = "0";
                })) : (_.append(g), w.style.display = "block"), document.body.style.overflow = "hidden", setTimeout(function () {
                  g.style.opacity = "1";
                }));
              },
              j = function () {
                r.isOpen(g) && (null == d || d(), g.style.opacity = "0", O.style[u] = "-100%", document.body.style.overflow = b, setTimeout(function () {
                  f.keepSession ? g.style.display = "none" : I();
                }, 500));
              };
            r.setAutoClose(m, f.autoClose, j), O.append(function (e) {
              var t = document.createElement("a");
              return t.className = "tf-v1-close tf-v1-close-icon", t.innerHTML = "&times;", t.onclick = e, t;
            }(j)), f.open && !r.isOpen(g) && (n = r.handleCustomOpen(P, f.open, f.openValue));
            var I = function () {
              r.unmountElement(g), window.removeEventListener("resize", E), f.open && (null == n ? void 0 : n.remove) && n.remove();
            };
            return {
              open: P,
              close: j,
              toggle: function () {
                r.isOpen(g) ? j() : P();
              },
              refresh: h,
              focus: y,
              unmount: I
            };
          };
        },
        331: function (e, t, n) {
          var o = (this || _global) && (this || _global).__createBinding || (Object.create ? function (e, t, n, o) {
              void 0 === o && (o = n), Object.defineProperty(e, o, {
                enumerable: !0,
                get: function () {
                  return t[n];
                }
              });
            } : function (e, t, n, o) {
              void 0 === o && (o = n), e[o] = t[n];
            }),
            r = (this || _global) && (this || _global).__exportStar || function (e, t) {
              for (var n in e) "default" === n || Object.prototype.hasOwnProperty.call(t, n) || o(t, e, n);
            };
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), r(n(603), t), r(n(162), t);
        },
        162: function (e, t) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          });
        },
        718: function (e, t, n) {
          var o = (this || _global) && (this || _global).__rest || function (e, t) {
              var n = {};
              for (var o in e) Object.prototype.hasOwnProperty.call(e, o) && t.indexOf(o) < 0 && (n[o] = e[o]);
              if (null != e && "function" == typeof Object.getOwnPropertySymbols) {
                var r = 0;
                for (o = Object.getOwnPropertySymbols(e); r < o.length; r++) t.indexOf(o[r]) < 0 && Object.prototype.propertyIsEnumerable.call(e, o[r]) && (n[o[r]] = e[o[r]]);
              }
              return n;
            },
            r = (this || _global) && (this || _global).__read || function (e, t) {
              var n = "function" == typeof Symbol && e[Symbol.iterator];
              if (!n) return e;
              var o,
                r,
                i = n.call(e),
                a = [];
              try {
                for (; (void 0 === t || t-- > 0) && !(o = i.next()).done;) a.push(o.value);
              } catch (e) {
                r = {
                  error: e
                };
              } finally {
                try {
                  o && !o.done && (n = i.return) && n.call(i);
                } finally {
                  if (r) throw r.error;
                }
              }
              return a;
            };
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.createWidget = void 0;
          var i = n(747),
            a = n(554),
            u = n(313),
            c = n(126);
          t.createWidget = function (e, t) {
            if (!i.hasDom()) return {
              refresh: function () {},
              focus: function () {},
              unmount: function () {}
            };
            var n = t.domain,
              s = o(t, ["domain"]);
            s.inlineOnMobile = t.inlineOnMobile || t.fullScreen, s.inlineOnMobile || !s.forceTouch && !i.isFullscreen() || (s.displayAsFullScreenModal = !0, s.forceTouch = !0);
            var d,
              l = i.createIframe("widget", {
                formId: e,
                domain: n,
                options: s
              }),
              f = l.embedId,
              p = l.iframe,
              v = l.refresh,
              m = l.focus,
              h = u.buildWidget(p, s.width, s.height);
            if (s.fullScreen && (c.overrideFullScreenStyles(t.container, p), d = i.makeAutoResize(t.container, !0), window.addEventListener("resize", d), d()), s.autoResize) {
              var y = r("string" == typeof s.autoResize ? s.autoResize.split(",").map(function (e) {
                  return parseInt(e);
                }) : [], 2),
                b = y[0],
                g = y[1];
              window.addEventListener("message", a.getFormHeightChangedHandler(f, function (e) {
                var n = Math.max(e.height, b || 0);
                g && (n = Math.min(n, g)), t.container.style.height = n + "px";
              }));
            }
            s.autoFocus && window.addEventListener("message", a.getFormReadyHandler(f, function () {
              setTimeout(function () {
                m();
              }, 1000);
            }));
            var w,
              O = function () {
                return t.container.append(h);
              };
            if (t.container.innerHTML = "", t.lazy ? i.lazyInitialize(t.container, O) : O(), s.displayAsFullScreenModal) {
              var _ = "",
                E = t.container;
              d = i.makeAutoResize(E);
              var P = E.style.height;
              window.addEventListener("message", a.getWelcomeScreenHiddenHandler(f, function () {
                E.classList.add("tf-v1-widget-fullscreen"), void 0 !== t.opacity && (E.style.backgroundColor = _), d(), window.addEventListener("resize", d);
              })), window.addEventListener("message", a.getFormThemeHandler(f, function (e) {
                var t;
                _ = i.changeColorOpacity(null === (t = null == e ? void 0 : e.theme) || void 0 === t ? void 0 : t.backgroundColor);
              }));
              var j = ((w = document.createElement("a")).className = "tf-v1-widget-close tf-v1-close-icon", w.innerHTML = "&times;", w);
              j.onclick = function () {
                var e;
                if (window.removeEventListener("resize", d), E.style.height = P, null === (e = t.onClose) || void 0 === e || e.call(t), E.classList.remove("tf-v1-widget-fullscreen"), E.style.backgroundColor = "", t.keepSession) {
                  var n = document.createElement("div");
                  n.className = "tf-v1-widget-iframe-overlay", n.onclick = function () {
                    E.classList.add("tf-v1-widget-fullscreen"), i.unmountElement(n);
                  }, h.append(n);
                } else t.container.innerHTML = "", O(), E.append(j);
              }, E.append(j);
            }
            return {
              refresh: v,
              focus: m,
              unmount: function () {
                d && window.removeEventListener("resize", d), i.unmountElement(h);
              }
            };
          };
        },
        419: function (e, t, n) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.buildWidget = void 0;
          var o = n(747);
          t.buildWidget = function (e, t, n) {
            var r = document.createElement("div");
            return r.className = "tf-v1-widget", r.dataset.testid = "tf-v1-widget", r.append(e), o.setElementSize(r, {
              width: t,
              height: n
            });
          };
        },
        313: function (e, t, n) {
          var o = (this || _global) && (this || _global).__createBinding || (Object.create ? function (e, t, n, o) {
              void 0 === o && (o = n), Object.defineProperty(e, o, {
                enumerable: !0,
                get: function () {
                  return t[n];
                }
              });
            } : function (e, t, n, o) {
              void 0 === o && (o = n), e[o] = t[n];
            }),
            r = (this || _global) && (this || _global).__exportStar || function (e, t) {
              for (var n in e) "default" === n || Object.prototype.hasOwnProperty.call(t, n) || o(t, e, n);
            };
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), r(n(419), t);
        },
        126: function (e, t) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.overrideFullScreenStyles = void 0, t.overrideFullScreenStyles = function (e, t) {
            Object.assign(e.style, {
              zIndex: "10001",
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%"
            }), t.style.borderRadius = "0", Object.assign(document.body.style, {
              overflow: "hidden"
            });
          };
        },
        321: function (e, t, n) {
          var o = (this || _global) && (this || _global).__createBinding || (Object.create ? function (e, t, n, o) {
              void 0 === o && (o = n), Object.defineProperty(e, o, {
                enumerable: !0,
                get: function () {
                  return t[n];
                }
              });
            } : function (e, t, n, o) {
              void 0 === o && (o = n), e[o] = t[n];
            }),
            r = (this || _global) && (this || _global).__exportStar || function (e, t) {
              for (var n in e) "default" === n || Object.prototype.hasOwnProperty.call(t, n) || o(t, e, n);
            };
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), r(n(718), t), r(n(58), t);
        },
        58: function (e, t) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          });
        },
        920: function (e, t, n) {
          var o = (this || _global) && (this || _global).__createBinding || (Object.create ? function (e, t, n, o) {
              void 0 === o && (o = n), Object.defineProperty(e, o, {
                enumerable: !0,
                get: function () {
                  return t[n];
                }
              });
            } : function (e, t, n, o) {
              void 0 === o && (o = n), e[o] = t[n];
            }),
            r = (this || _global) && (this || _global).__exportStar || function (e, t) {
              for (var n in e) "default" === n || Object.prototype.hasOwnProperty.call(t, n) || o(t, e, n);
            };
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), r(n(797), t), r(n(970), t), r(n(331), t), r(n(321), t), r(n(434), t);
        },
        626: function (e, t, n) {
          var o = (this || _global) && (this || _global).__assign || function () {
              return o = Object.assign || function (e) {
                for (var t, n = 1, o = arguments.length; n < o; n++) for (var r in t = arguments[n]) Object.prototype.hasOwnProperty.call(t, r) && (e[r] = t[r]);
                return e;
              }, o.apply(this || _global, arguments);
            },
            r = (this || _global) && (this || _global).__read || function (e, t) {
              var n = "function" == typeof Symbol && e[Symbol.iterator];
              if (!n) return e;
              var o,
                r,
                i = n.call(e),
                a = [];
              try {
                for (; (void 0 === t || t-- > 0) && !(o = i.next()).done;) a.push(o.value);
              } catch (e) {
                r = {
                  error: e
                };
              } finally {
                try {
                  o && !o.done && (n = i.return) && n.call(i);
                } finally {
                  if (r) throw r.error;
                }
              }
              return a;
            };
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.buildIframeSrc = void 0;
          var i = n(27),
            a = n(527),
            u = n(346),
            c = n(698),
            s = n(863),
            d = {
              widget: "embed-widget",
              popup: "popup-blank",
              slider: "popup-drawer",
              popover: "popup-popover",
              "side-tab": "popup-side-panel"
            };
          t.buildIframeSrc = function (e) {
            var t = e.domain,
              n = e.formId,
              l = e.type,
              f = e.embedId,
              p = e.options,
              v = function (e, t, n) {
                var r = n.transitiveSearchParams,
                  i = n.source,
                  a = n.medium,
                  u = n.mediumVersion,
                  s = n.hideFooter,
                  l = n.hideHeaders,
                  f = n.opacity,
                  p = n.disableTracking,
                  v = n.enableSandbox,
                  m = n.shareGaInstance,
                  h = n.forceTouch,
                  y = n.displayAsFullScreenModal,
                  b = n.tracking,
                  g = n.redirectTarget,
                  w = n.autoResize,
                  O = n.disableScroll,
                  _ = n.onEndingButtonClick,
                  E = n.noHeading,
                  P = c.getTransitiveSearchParams(r);
                return o(o(o({}, {
                  "typeform-embed-id": t,
                  "typeform-embed": d[e],
                  "typeform-source": i,
                  "typeform-medium": a,
                  "typeform-medium-version": u,
                  "embed-hide-footer": s ? "true" : void 0,
                  "embed-hide-headers": l ? "true" : void 0,
                  "embed-opacity": f,
                  "disable-tracking": p || v ? "true" : void 0,
                  "__dangerous-disable-submissions": v ? "true" : void 0,
                  "share-ga-instance": m ? "true" : void 0,
                  "force-touch": h ? "true" : void 0,
                  "add-placeholder-ws": "widget" === e && y ? "true" : void 0,
                  "typeform-embed-redirect-target": g,
                  "typeform-embed-auto-resize": w ? "true" : void 0,
                  "typeform-embed-disable-scroll": O ? "true" : void 0,
                  "typeform-embed-handle-ending-button-click": _ ? "true" : void 0,
                  "typeform-embed-no-heading": E ? "true" : void 0
                }), P), b);
              }(l, f, function (e) {
                return e.noHeading || (e.noHeading = document.querySelectorAll("h1").length > 0), o(o({}, {
                  source: null === (t = null === window || void 0 === window ? void 0 : window.location) || void 0 === t ? void 0 : t.hostname.replace(/^www\./, ""),
                  medium: "embed-sdk",
                  mediumVersion: "next"
                }), a.removeUndefinedKeys(e));
                var t;
              }(p)),
              m = function (e, t, n) {
                return void 0 === t && (t = i.DEFAULT_DOMAIN), void 0 === n && (n = !1), e.startsWith("http://") || e.startsWith("https://") ? new URL(e) : new URL("https://" + t + "/" + (n ? "c" : "to") + "/" + e);
              }(n, t, p.chat);
            if (Object.entries(v).filter(function (e) {
              var t = r(e, 2)[1];
              return u.isDefined(t);
            }).forEach(function (e) {
              var t = r(e, 2),
                n = t[0],
                o = t[1];
              m.searchParams.set(n, o);
            }), p.hubspot) {
              var h = s.getHubspotHiddenFields();
              p.hidden = o(o({}, p.hidden), h);
            }
            if (p.hidden) {
              var y = new URLSearchParams();
              Object.entries(p.hidden).filter(function (e) {
                var t = r(e, 2)[1];
                return u.isDefined(t) && "" !== t;
              }).forEach(function (e) {
                var t = r(e, 2),
                  n = t[0],
                  o = t[1];
                "boolean" == typeof p.transitiveSearchParams && m.searchParams.delete(n), y.set(n, o);
              });
              var b = y.toString();
              b && (m.hash = b);
            }
            return m.href;
          };
        },
        391: function (e, t) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.changeColorOpacity = void 0, t.changeColorOpacity = function (e, t) {
            return void 0 === e && (e = ""), void 0 === t && (t = 255), e.startsWith("rgba(") ? null == e ? void 0 : e.replace(/, [\d.]+\)$/, ", " + t + ")") : e;
          };
        },
        972: function (e, t) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.handleCustomOpen = void 0;
          var n = {
            remove: function () {}
          };
          t.handleCustomOpen = function (e, t, o) {
            switch (t) {
              case "load":
                return e(), n;
              case "exit":
                return o ? function (e, t) {
                  var n = 0,
                    o = function (r) {
                      r.clientY < e && r.clientY < n ? (document.removeEventListener("mousemove", o, !0), t()) : n = r.clientY;
                    };
                  return document.addEventListener("mousemove", o, !0), {
                    remove: function () {
                      return document.removeEventListener("mousemove", o, !0);
                    }
                  };
                }(o, e) : n;
              case "time":
                return setTimeout(function () {
                  e();
                }, o), n;
              case "scroll":
                return o ? function (e, t) {
                  function n() {
                    var o = window.pageYOffset || document.documentElement.scrollTop,
                      r = document.documentElement.clientTop || 0,
                      i = document.documentElement.scrollHeight,
                      a = o - r,
                      u = a / i * 100,
                      c = a + window.innerHeight >= i;
                    (u >= e || c) && (t(), document.removeEventListener("scroll", n));
                  }
                  return document.addEventListener("scroll", n), {
                    remove: function () {
                      return document.removeEventListener("scroll", n);
                    }
                  };
                }(o, e) : n;
              default:
                return n;
            }
          };
        },
        553: function (e, t, n) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.createIframe = void 0;
          var o = n(626),
            r = n(747),
            i = n(866),
            a = n(554),
            u = n(256),
            c = n(144),
            s = n(511);
          t.createIframe = function (e, t) {
            var n = t.formId,
              d = t.domain,
              l = t.options,
              f = i.generateEmbedId(),
              p = l.iframeProps,
              v = void 0 === p ? {} : p,
              m = l.onReady,
              h = l.onQuestionChanged,
              y = l.onHeightChanged,
              b = l.onSubmit,
              g = l.onEndingButtonClick,
              w = l.shareGaInstance,
              O = o.buildIframeSrc({
                formId: n,
                domain: d,
                embedId: f,
                type: e,
                options: l
              }),
              _ = document.createElement("iframe");
            return _.src = O, _.dataset.testid = "iframe", _.style.border = "0px", _.allow = "microphone; camera", Object.keys(v).forEach(function (e) {
              _.setAttribute(e, v[e]);
            }), _.addEventListener("load", u.triggerIframeRedraw, {
              once: !0
            }), window.addEventListener("message", a.getFormReadyHandler(f, m)), window.addEventListener("message", a.getFormQuestionChangedHandler(f, h)), window.addEventListener("message", a.getFormHeightChangedHandler(f, y)), window.addEventListener("message", a.getFormSubmitHandler(f, b)), window.addEventListener("message", a.getFormThemeHandler(f, function (e) {
              var t;
              if (null == e ? void 0 : e.theme) {
                var n = document.querySelector(".tf-v1-close-icon");
                n && (n.style.color = null === (t = e.theme) || void 0 === t ? void 0 : t.color);
              }
            })), window.addEventListener("message", a.getThankYouScreenButtonClickHandler(f, g)), "widget" !== e && window.addEventListener("message", c.dispatchCustomKeyEventFromIframe), w && window.addEventListener("message", a.getFormReadyHandler(f, function () {
              r.setupGaInstance(_, f, w);
            })), {
              iframe: _,
              embedId: f,
              refresh: function () {
                return s.refreshIframe(_);
              },
              focus: function () {
                var e;
                null === (e = _.contentWindow) || void 0 === e || e.postMessage("embed-focus", "*");
              }
            };
          };
        },
        866: function (e, t) {
          var n = (this || _global) && (this || _global).__read || function (e, t) {
            var n = "function" == typeof Symbol && e[Symbol.iterator];
            if (!n) return e;
            var o,
              r,
              i = n.call(e),
              a = [];
            try {
              for (; (void 0 === t || t-- > 0) && !(o = i.next()).done;) a.push(o.value);
            } catch (e) {
              r = {
                error: e
              };
            } finally {
              try {
                o && !o.done && (n = i.return) && n.call(i);
              } finally {
                if (r) throw r.error;
              }
            }
            return a;
          };
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.generateEmbedId = void 0, t.generateEmbedId = function () {
            var e = Math.random();
            return n(String(e).split("."), 2)[1];
          };
        },
        554: function (e, t) {
          var n = (this || _global) && (this || _global).__rest || function (e, t) {
            var n = {};
            for (var o in e) Object.prototype.hasOwnProperty.call(e, o) && t.indexOf(o) < 0 && (n[o] = e[o]);
            if (null != e && "function" == typeof Object.getOwnPropertySymbols) {
              var r = 0;
              for (o = Object.getOwnPropertySymbols(e); r < o.length; r++) t.indexOf(o[r]) < 0 && Object.prototype.propertyIsEnumerable.call(e, o[r]) && (n[o[r]] = e[o[r]]);
            }
            return n;
          };
          function o(e, t, o) {
            return function (r) {
              var i = r.data,
                a = i.type,
                u = i.embedId,
                c = n(i, ["type", "embedId"]);
              a === e && u === t && (null == o || o(c));
            };
          }
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.getThankYouScreenButtonClickHandler = t.getFormThemeHandler = t.getWelcomeScreenHiddenHandler = t.getFormSubmitHandler = t.getFormHeightChangedHandler = t.getFormQuestionChangedHandler = t.getFormReadyHandler = void 0, t.getFormReadyHandler = function (e, t) {
            return o("form-ready", e, t);
          }, t.getFormQuestionChangedHandler = function (e, t) {
            return o("form-screen-changed", e, t);
          }, t.getFormHeightChangedHandler = function (e, t) {
            return o("form-height-changed", e, t);
          }, t.getFormSubmitHandler = function (e, t) {
            return o("form-submit", e, t);
          }, t.getWelcomeScreenHiddenHandler = function (e, t) {
            return o("welcome-screen-hidden", e, t);
          }, t.getFormThemeHandler = function (e, t) {
            return o("form-theme", e, t);
          }, t.getThankYouScreenButtonClickHandler = function (e, t) {
            return o("thank-you-screen-button-click", e, t);
          };
        },
        339: function (e, t, n) {
          var o = (this || _global) && (this || _global).__createBinding || (Object.create ? function (e, t, n, o) {
              void 0 === o && (o = n), Object.defineProperty(e, o, {
                enumerable: !0,
                get: function () {
                  return t[n];
                }
              });
            } : function (e, t, n, o) {
              void 0 === o && (o = n), e[o] = t[n];
            }),
            r = (this || _global) && (this || _global).__exportStar || function (e, t) {
              for (var n in e) "default" === n || Object.prototype.hasOwnProperty.call(t, n) || o(t, e, n);
            };
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), r(n(553), t), r(n(144), t);
        },
        511: function (e, t) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.refreshIframe = void 0, t.refreshIframe = function (e) {
            if (e) {
              var t = e.src;
              if (t.includes("&refresh")) {
                var n = t.split("&refresh#");
                e.src = n.join("#");
              } else (n = t.split("#"))[0] = n[0] + "&refresh", e.src = n.join("#");
            }
          };
        },
        144: function (e, t) {
          var n = (this || _global) && (this || _global).__awaiter || function (e, t, n, o) {
              return new (n || (n = Promise))(function (r, i) {
                function a(e) {
                  try {
                    c(o.next(e));
                  } catch (e) {
                    i(e);
                  }
                }
                function u(e) {
                  try {
                    c(o.throw(e));
                  } catch (e) {
                    i(e);
                  }
                }
                function c(e) {
                  var t;
                  e.done ? r(e.value) : (t = e.value, t instanceof n ? t : new n(function (e) {
                    e(t);
                  })).then(a, u);
                }
                c((o = o.apply(e, t || [])).next());
              });
            },
            o = (this || _global) && (this || _global).__generator || function (e, t) {
              var n,
                o,
                r,
                i,
                a = {
                  label: 0,
                  sent: function () {
                    if (1 & r[0]) throw r[1];
                    return r[1];
                  },
                  trys: [],
                  ops: []
                };
              return i = {
                next: u(0),
                throw: u(1),
                return: u(2)
              }, "function" == typeof Symbol && (i[Symbol.iterator] = function () {
                return this || _global;
              }), i;
              function u(i) {
                return function (u) {
                  return function (i) {
                    if (n) throw new TypeError("Generator is already executing.");
                    for (; a;) try {
                      if (n = 1, o && (r = 2 & i[0] ? o.return : i[0] ? o.throw || ((r = o.return) && r.call(o), 0) : o.next) && !(r = r.call(o, i[1])).done) return r;
                      switch (o = 0, r && (i = [2 & i[0], r.value]), i[0]) {
                        case 0:
                        case 1:
                          r = i;
                          break;
                        case 4:
                          return a.label++, {
                            value: i[1],
                            done: !1
                          };
                        case 5:
                          a.label++, o = i[1], i = [0];
                          continue;
                        case 7:
                          i = a.ops.pop(), a.trys.pop();
                          continue;
                        default:
                          if (!((r = (r = a.trys).length > 0 && r[r.length - 1]) || 6 !== i[0] && 2 !== i[0])) {
                            a = 0;
                            continue;
                          }
                          if (3 === i[0] && (!r || i[1] > r[0] && i[1] < r[3])) {
                            a.label = i[1];
                            break;
                          }
                          if (6 === i[0] && a.label < r[1]) {
                            a.label = r[1], r = i;
                            break;
                          }
                          if (r && a.label < r[2]) {
                            a.label = r[2], a.ops.push(i);
                            break;
                          }
                          r[2] && a.ops.pop(), a.trys.pop();
                          continue;
                      }
                      i = t.call(e, a);
                    } catch (e) {
                      i = [6, e], o = 0;
                    } finally {
                      n = r = 0;
                    }
                    if (5 & i[0]) throw i[1];
                    return {
                      value: i[0] ? i[1] : void 0,
                      done: !0
                    };
                  }([i, u]);
                };
              }
            };
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.dispatchCustomKeyEventFromIframe = t.removeCustomKeyboardListener = t.addCustomKeyboardListener = void 0;
          var r = "Escape",
            i = function (e, i) {
              return n(void 0, void 0, void 0, function () {
                return o(this || _global, function (n) {
                  return e.code === r && "function" == typeof i && (i(), t.removeCustomKeyboardListener()), [2];
                });
              });
            };
          t.addCustomKeyboardListener = function (e) {
            return window.document.addEventListener("keydown", function (t) {
              return i(t, e);
            });
          }, t.removeCustomKeyboardListener = function () {
            return window.document.removeEventListener("keydown", i);
          }, t.dispatchCustomKeyEventFromIframe = function (e) {
            "form-close" === e.data.type && window.document.dispatchEvent(new KeyboardEvent("keydown", {
              code: r
            }));
          };
        },
        256: function (e, t) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.triggerIframeRedraw = void 0, t.triggerIframeRedraw = function () {
            (this || _global).style.transform = "translateZ(0)";
          };
        },
        939: function (e, t, n) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.getTextColor = void 0;
          var o = n(938);
          t.getTextColor = function (e) {
            if (!e) return "#FFFFFF";
            var t = e.startsWith("#") ? o.hexRgb(e) : function (e) {
                var t = {
                    red: 0,
                    green: 0,
                    blue: 0
                  },
                  n = e.match(/\d+/g);
                return n && (t.red = parseInt(n[0], 10), t.green = parseInt(n[0], 10), t.blue = parseInt(n[0], 10)), t;
              }(e),
              n = t.red,
              r = t.green,
              i = t.blue;
            return Math.round((299 * n + 587 * r + 114 * i) / 1000) > 150 ? "#000000" : "#FFFFFF";
          };
        },
        698: function (e, t) {
          var n = (this || _global) && (this || _global).__assign || function () {
            return n = Object.assign || function (e) {
              for (var t, n = 1, o = arguments.length; n < o; n++) for (var r in t = arguments[n]) Object.prototype.hasOwnProperty.call(t, r) && (e[r] = t[r]);
              return e;
            }, n.apply(this || _global, arguments);
          };
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.getTransitiveSearchParams = void 0, t.getTransitiveSearchParams = function (e) {
            var t = new URL(window.location.href);
            return "boolean" == typeof e && e ? Object.fromEntries(t.searchParams.entries()) : Array.isArray(e) && e.length > 0 ? e.reduce(function (e, o) {
              var r,
                i = t.searchParams.get(o);
              return i ? n(n({}, e), ((r = {})[o] = i, r)) : e;
            }, {}) : {};
          };
        },
        252: function (e, t) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.hasDom = void 0, t.hasDom = function () {
            return "undefined" != typeof document && "undefined" != typeof window;
          };
        },
        938: function (e, t) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.hexRgb = void 0;
          var n = new RegExp("[^#a-f\\d]", "gi"),
            o = new RegExp("^#?[a-f\\d]{3}[a-f\\d]?$|^#?[a-f\\d]{6}([a-f\\d]{2})?$", "i");
          t.hexRgb = function (e) {
            if ("string" != typeof e || n.test(e) || !o.test(e)) throw new TypeError("Expected a valid hex string");
            8 === (e = e.replace(/^#/, "")).length && (e = e.slice(0, 6)), 4 === e.length && (e = e.slice(0, 3)), 3 === e.length && (e = e[0] + e[0] + e[1] + e[1] + e[2] + e[2]);
            var t = Number.parseInt(e, 16);
            return {
              red: t >> 16,
              green: t >> 8 & 255,
              blue: 255 & t
            };
          };
        },
        863: function (e, t, n) {
          var o = (this || _global) && (this || _global).__awaiter || function (e, t, n, o) {
              return new (n || (n = Promise))(function (r, i) {
                function a(e) {
                  try {
                    c(o.next(e));
                  } catch (e) {
                    i(e);
                  }
                }
                function u(e) {
                  try {
                    c(o.throw(e));
                  } catch (e) {
                    i(e);
                  }
                }
                function c(e) {
                  var t;
                  e.done ? r(e.value) : (t = e.value, t instanceof n ? t : new n(function (e) {
                    e(t);
                  })).then(a, u);
                }
                c((o = o.apply(e, t || [])).next());
              });
            },
            r = (this || _global) && (this || _global).__generator || function (e, t) {
              var n,
                o,
                r,
                i,
                a = {
                  label: 0,
                  sent: function () {
                    if (1 & r[0]) throw r[1];
                    return r[1];
                  },
                  trys: [],
                  ops: []
                };
              return i = {
                next: u(0),
                throw: u(1),
                return: u(2)
              }, "function" == typeof Symbol && (i[Symbol.iterator] = function () {
                return this || _global;
              }), i;
              function u(i) {
                return function (u) {
                  return function (i) {
                    if (n) throw new TypeError("Generator is already executing.");
                    for (; a;) try {
                      if (n = 1, o && (r = 2 & i[0] ? o.return : i[0] ? o.throw || ((r = o.return) && r.call(o), 0) : o.next) && !(r = r.call(o, i[1])).done) return r;
                      switch (o = 0, r && (i = [2 & i[0], r.value]), i[0]) {
                        case 0:
                        case 1:
                          r = i;
                          break;
                        case 4:
                          return a.label++, {
                            value: i[1],
                            done: !1
                          };
                        case 5:
                          a.label++, o = i[1], i = [0];
                          continue;
                        case 7:
                          i = a.ops.pop(), a.trys.pop();
                          continue;
                        default:
                          if (!((r = (r = a.trys).length > 0 && r[r.length - 1]) || 6 !== i[0] && 2 !== i[0])) {
                            a = 0;
                            continue;
                          }
                          if (3 === i[0] && (!r || i[1] > r[0] && i[1] < r[3])) {
                            a.label = i[1];
                            break;
                          }
                          if (6 === i[0] && a.label < r[1]) {
                            a.label = r[1], r = i;
                            break;
                          }
                          if (r && a.label < r[2]) {
                            a.label = r[2], a.ops.push(i);
                            break;
                          }
                          r[2] && a.ops.pop(), a.trys.pop();
                          continue;
                      }
                      i = t.call(e, a);
                    } catch (e) {
                      i = [6, e], o = 0;
                    } finally {
                      n = r = 0;
                    }
                    if (5 & i[0]) throw i[1];
                    return {
                      value: i[0] ? i[1] : void 0,
                      done: !0
                    };
                  }([i, u]);
                };
              }
            };
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.waitForHubspotCookie = t.getHubspotHiddenFields = t.getHubspotCookieValue = void 0;
          var i = n(623);
          t.getHubspotCookieValue = function () {
            var e = document.cookie.match(new RegExp("(^| )hubspotutk=([^;]+)"));
            return e && e[2] || void 0;
          }, t.getHubspotHiddenFields = function () {
            return {
              hubspot_page_name: document.title,
              hubspot_page_url: window.location.href,
              hubspot_utk: t.getHubspotCookieValue()
            };
          }, t.waitForHubspotCookie = function () {
            return o(void 0, void 0, void 0, function () {
              var e, n;
              return r(this || _global, function (o) {
                switch (o.label) {
                  case 0:
                    e = 10, n = 0, o.label = 1;
                  case 1:
                    return !t.getHubspotCookieValue() && n < e ? [4, i.sleep(250)] : [3, 4];
                  case 2:
                    o.sent(), o.label = 3;
                  case 3:
                    return n++, [3, 1];
                  case 4:
                    return [2];
                }
              });
            });
          };
        },
        71: function (e, t) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.includeCss = void 0, t.includeCss = function (e) {
            var t = function (e) {
              return "https://embed.typeform.com/next/css/" + e;
            }(e);
            if (!document.querySelector("link[href=\"" + t + "\"]")) {
              var n = document.createElement("link");
              n.rel = "stylesheet", n.href = t, document.head.append(n);
            }
          };
        },
        747: function (e, t, n) {
          var o = (this || _global) && (this || _global).__createBinding || (Object.create ? function (e, t, n, o) {
              void 0 === o && (o = n), Object.defineProperty(e, o, {
                enumerable: !0,
                get: function () {
                  return t[n];
                }
              });
            } : function (e, t, n, o) {
              void 0 === o && (o = n), e[o] = t[n];
            }),
            r = (this || _global) && (this || _global).__exportStar || function (e, t) {
              for (var n in e) "default" === n || Object.prototype.hasOwnProperty.call(t, n) || o(t, e, n);
            };
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), r(n(626), t), r(n(339), t), r(n(252), t), r(n(71), t), r(n(346), t), r(n(377), t), r(n(563), t), r(n(527), t), r(n(533), t), r(n(451), t), r(n(972), t), r(n(748), t), r(n(392), t), r(n(939), t), r(n(917), t), r(n(987), t), r(n(318), t), r(n(391), t), r(n(863), t);
        },
        346: function (e, t) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.isDefined = void 0, t.isDefined = function (e) {
            return null != e;
          };
        },
        987: function (e, t) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.isVisible = t.isInPage = t.isOpen = void 0, t.isOpen = function (e) {
            return t.isInPage(e) && t.isVisible(e);
          }, t.isInPage = function (e) {
            return !!e.parentNode;
          }, t.isVisible = function (e) {
            return "none" !== e.style.display;
          };
        },
        917: function (e, t) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.lazyInitialize = void 0, t.lazyInitialize = function (e, t) {
            var n = new IntersectionObserver(function (e) {
              e.forEach(function (e) {
                e.isIntersecting && (t(), n.unobserve(e.target));
              });
            });
            n.observe(e);
          };
        },
        377: function (e, t) {
          var n = (this || _global) && (this || _global).__assign || function () {
              return n = Object.assign || function (e) {
                for (var t, n = 1, o = arguments.length; n < o; n++) for (var r in t = arguments[n]) Object.prototype.hasOwnProperty.call(t, r) && (e[r] = t[r]);
                return e;
              }, n.apply(this || _global, arguments);
            },
            o = (this || _global) && (this || _global).__read || function (e, t) {
              var n = "function" == typeof Symbol && e[Symbol.iterator];
              if (!n) return e;
              var o,
                r,
                i = n.call(e),
                a = [];
              try {
                for (; (void 0 === t || t-- > 0) && !(o = i.next()).done;) a.push(o.value);
              } catch (e) {
                r = {
                  error: e
                };
              } finally {
                try {
                  o && !o.done && (n = i.return) && n.call(i);
                } finally {
                  if (r) throw r.error;
                }
              }
              return a;
            };
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.loadOptionsFromAttributes = t.transformAttributeValue = t.camelCaseToKebabCase = void 0, t.camelCaseToKebabCase = function (e) {
            return e.split("").map(function (e, t) {
              return e.toUpperCase() === e ? (0 !== t ? "-" : "") + e.toLowerCase() : e;
            }).join("");
          };
          var r = function (e) {
              return e || void 0;
            },
            i = function (e) {
              return "" === e || "yes" === e || "true" === e;
            },
            a = function (e) {
              var t = e ? parseInt(e, 10) : NaN;
              return isNaN(t) ? void 0 : t;
            },
            u = "%ESCAPED_COMMA%",
            c = function (e) {
              if (e) return e.replace(/\s/g, "").replace(/\\,/g, u).split(",").filter(function (e) {
                return !!e;
              }).map(function (e) {
                return e.replace(u, ",");
              });
            };
          t.transformAttributeValue = function (e, t) {
            var s, d, l;
            switch (t) {
              case "string":
                return r(e);
              case "boolean":
                return i(e);
              case "integer":
                return a(e);
              case "function":
                return function (e) {
                  var t = e && e in window ? window[e] : void 0;
                  return "function" == typeof t ? t : void 0;
                }(e);
              case "array":
                return c(e);
              case "record":
                return function (e) {
                  if (e) return e.replace(/\\,/g, u).split(",").filter(function (e) {
                    return !!e;
                  }).map(function (e) {
                    return e.replace(u, ",");
                  }).reduce(function (e, t) {
                    var r,
                      i = t.match(/^([^=]+)=(.*)$/);
                    if (i) {
                      var a = o(i, 3),
                        u = a[1],
                        c = a[2];
                      return n(n({}, e), ((r = {})[u.trim()] = c, r));
                    }
                    return e;
                  }, {});
                }(e);
              case "integerOrBoolean":
                return null !== (s = a(e)) && void 0 !== s ? s : i(e);
              case "stringOrBoolean":
                return null !== (d = r(e)) && void 0 !== d ? d : i(e);
              case "arrayOrBoolean":
                return null !== (l = c(e)) && void 0 !== l ? l : i(e);
              default:
                throw new Error("Invalid attribute transformation " + t);
            }
          }, t.loadOptionsFromAttributes = function (e, o) {
            return Object.keys(o).reduce(function (r, i) {
              var a;
              return n(n({}, r), ((a = {})[i] = t.transformAttributeValue(e.getAttribute("data-tf-" + t.camelCaseToKebabCase(i)), o[i]), a));
            }, {});
          };
        },
        318: function (e, t, n) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.makeAutoResize = void 0;
          var o = n(563);
          t.makeAutoResize = function (e, t) {
            return void 0 === t && (t = !1), function () {
              var n = t || o.isMobile();
              e && n && e.style.setProperty("height", window.innerHeight + "px", "important");
            };
          };
        },
        563: function (e, t) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.isFullscreen = t.isMobile = t.isBigScreen = void 0, t.isBigScreen = function () {
            return window.screen.width >= 1024 && window.screen.height >= 768;
          }, t.isMobile = function () {
            return /mobile|tablet|android/i.test(navigator.userAgent.toLowerCase());
          }, t.isFullscreen = function () {
            return t.isMobile() && !t.isBigScreen();
          };
        },
        527: function (e, t, n) {
          var o = (this || _global) && (this || _global).__assign || function () {
              return o = Object.assign || function (e) {
                for (var t, n = 1, o = arguments.length; n < o; n++) for (var r in t = arguments[n]) Object.prototype.hasOwnProperty.call(t, r) && (e[r] = t[r]);
                return e;
              }, o.apply(this || _global, arguments);
            },
            r = (this || _global) && (this || _global).__read || function (e, t) {
              var n = "function" == typeof Symbol && e[Symbol.iterator];
              if (!n) return e;
              var o,
                r,
                i = n.call(e),
                a = [];
              try {
                for (; (void 0 === t || t-- > 0) && !(o = i.next()).done;) a.push(o.value);
              } catch (e) {
                r = {
                  error: e
                };
              } finally {
                try {
                  o && !o.done && (n = i.return) && n.call(i);
                } finally {
                  if (r) throw r.error;
                }
              }
              return a;
            };
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.removeUndefinedKeys = void 0;
          var i = n(346);
          t.removeUndefinedKeys = function (e) {
            return Object.entries(e).filter(function (e) {
              var t = r(e, 2)[1];
              return i.isDefined(t);
            }).reduce(function (e, t) {
              var n,
                i = r(t, 2),
                a = i[0],
                u = i[1];
              return o(o({}, e), ((n = {})[a] = u, n));
            }, {});
          };
        },
        748: function (e, t, n) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.setAutoClose = void 0;
          var o = n(554);
          t.setAutoClose = function (e, t, n) {
            if (t && n) {
              var r = "number" == typeof t ? t : 0;
              window.addEventListener("message", o.getFormSubmitHandler(e, function () {
                return setTimeout(n, r);
              }));
            }
          };
        },
        533: function (e, t) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.setElementSize = void 0, t.setElementSize = function (e, t) {
            var n = t.width,
              o = t.height;
            return n && (e.style.width = n + "px"), o && (e.style.height = o + "px"), e;
          };
        },
        392: function (e, t) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.setupGaInstance = t.sendGaIdMessage = void 0, t.sendGaIdMessage = function (e, t, n) {
            var o = {
              embedId: e,
              gaClientId: t
            };
            setTimeout(function () {
              n && n.contentWindow && n.contentWindow.postMessage({
                type: "ga-client-id",
                data: o
              }, "*");
            }, 0);
          };
          var n = function (e) {
            console.error(e);
          };
          t.setupGaInstance = function (e, o, r) {
            var i = "string" == typeof r ? r : void 0;
            if (window.gtag) {
              if (i || (i = function () {
                if (window.dataLayer) {
                  var e = window.dataLayer.find(function (e) {
                    return e.length > 1 && "config" === e[0];
                  });
                  return e && e[1];
                }
              }()), !i) return void n("Whoops! You enabled the shareGaInstance feature in yourtypeform embed but the tracker ID could not be retrieved. Make sure to include Google Analytics Javascript code before the Typeform Embed Javascriptcode in your page and use correct tracker ID. ");
              var a = !1;
              window.gtag("get", i, "client_id", function (n) {
                a = !0, t.sendGaIdMessage(o, n, e);
              }), setTimeout(function () {
                a || n("Whoops! You enabled the shareGaInstance feature in yourtypeform embed but the tracker with ID " + i + " was not found. Make sure to include Google Analytics Javascript code before the Typeform Embed Javascriptcode in your page and use correct tracker ID. ");
              }, 3000);
            } else try {
              var u = function (e, t) {
                return t ? e.find(function (e) {
                  return e.get("trackingId") === t;
                }) : e[0];
              }(window.ga.getAll(), i);
              u ? t.sendGaIdMessage(o, u.get("clientId"), e) : n("Whoops! You enabled the shareGaInstance feature in yourtypeform embed but the tracker with ID " + i + " was not found. Make sure to include Google Analytics Javascript code before the Typeform Embed Javascriptcode in your page and use correct tracker ID. ");
            } catch (e) {
              n("Whoops! You enabled the shareGaInstance feature in your typeform embed but the Google Analytics object has not been found. Make sure to include Google Analytics Javascript code before the Typeform Embed Javascript code in your page. "), n(e);
            }
          };
        },
        623: function (e, t) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.sleep = void 0, t.sleep = function (e) {
            return new Promise(function (t) {
              return setTimeout(t, e);
            });
          };
        },
        451: function (e, t) {
          Object.defineProperty(t, "__esModule", {
            value: !0
          }), t.unmountElement = void 0, t.unmountElement = function (e) {
            var t;
            null === (t = e.parentNode) || void 0 === t || t.removeChild(e);
          };
        }
      },
      t = {};
    return function n(o) {
      var r = t[o];
      if (void 0 !== r) return r.exports;
      var i = t[o] = {
        exports: {}
      };
      return e[o].call(i.exports, i, i.exports, n), i.exports;
    }(920);
  }();
});
export default exports;
export const embed = exports.embed;